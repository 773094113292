import checked from '../assets/check.png' 
import { useNavigate } from "react-router-dom";
import HeroImg from '../assets/Hero.jpg'
import firstImage from '../assets/pro-1-1.png'
import SecondImage from '../assets/pro-1-2.png'
import thirdImage from '../assets/pro-1-3.png'
import fourthImage from '../assets/pro-1-4.png'
import list from '../assets/list-check.svg'
import SuccCopration from '../assets/شركاء موقع (1).jpg'
import Markat from '../assets/ماركات موقع.jpg'
import listo from '../assets/new-employee.png'
import img1 from '../assets/fe-m-2-1.png'
import img2 from '../assets/fe-m-2-2.png'
import img3 from '../assets/fe-m-2-3.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import Slider from '../Component/Slider';
import { Helmet } from 'react-helmet-async';


function Home() {
  const navigate = useNavigate()

  return (
    <>
          <Helmet>
        <title>  الصفحة الرئيسية - رؤية لتمويل السيارات</title>
        <meta
      name="description"
      content=" الصفحة الرئيسية رؤية لتمويل السيارات للافراد والشركات وللتمويل الشخصي "
      /> 
      <link rel="canonical" href="/Home"></link>
      
      </Helmet>
      <Slider/>
        <div data-aos="fade-up" className=' bg-white font-extrabold px-2 text-base text-right p-5 '>
          <div className='md:flex md:flex-row-reverse md:justify-between '>
      <div  className='md:flex-col p-7'>
      <p className='text-black text-3xl font-bold md:text-3xl'>
        <span className=' space-x-3 font-sans py-3 font-bold  text-[rgb(47,114,140)] underline rounded-3xl '
        >رؤية</span>  لتمويل السيارات بأسهل الطرق وأفضل الشروط

      </p>
      <div className=' text-gray-600 pt-7 pb-9 text-xl md:text-lg md:w-[600px]  '   >
      مع رؤية استفيد من خبراتنا الواسعة في تقديم حلول تمويل السيارات المناسبة لك.
       دعنا نساعدك في تحقيق أحلامك بامتالك السيارة التي تستحقها، بشروط ميسرة وإجراءات سريعة
      
      </div>
      </div>
      <div>
        <img data-aos="fade-up" data-aos-duration="1000" className=' rounded-2xl ' src={HeroImg} alt='رؤية للتمويل'/>
      </div>
          </div>

    </div>
    {/* ---------------------Middle Card---------------- */}
    <div  data-aos="fade-up" className=' flex justify-end px-3 md:absolute md:top-[930px] md:left-[400px] '>
 <div  className=' bg-white text-right md:rounded-xl   '>

<div className=' w-[320px]  px-3 py-5 border border-slate-600 text-slate-700  rounded-2xl shadow-lg'>


<p className=' flex justify-between pb-2'>
<img src={checked} alt='/ رؤية لتمويل السيارات' className='w-6 h-6  bg-[rgb(47,114,140)] rounded-full'/>
وسيط رقمي كامل

</p>

<p className=' flex justify-between pb-2'>
<img src={checked} alt='/ رؤية لتمويل السيارات' className='w-6 h-6 bg-[rgb(47,114,140)] rounded-full'/>
افضل فائده وسعر داخل المملكة 
</p>


<p className=' flex justify-between '>
<img src={checked} alt='/ رؤية لتمويل السيارات' className='w-6 h-6 bg-[rgb(47,114,140)] rounded-full space-x-7'/>
نخدمك بحب دائما على مدار الاسبوع
</p>

</div>
</div> 
    </div>
    {/* -----------------------------------------SWEAPERS------------------------------------ */}
    <div className=' text-black text-3xl text-center py-3 h-[150px] px-5'>
  <button className='btn text-white md:text-3xl text-lg py-5 bg-[rgb(47,114,140)]  h-[100px] md:h-[100px] md:w-[800px] '> <span className="loading loading-ring loading-lg"></span> <a href='/IndividualRequest'>تمويل سيارات للأفراد</a>  <span className="loading loading-ring loading-lg"></span></button>
</div>

<div className=' text-black text-3xl text-center py-3 h-[150px]'>
  <button className='btn text-white md:text-3xl text-lg py-5 bg-[rgb(47,114,140)]  h-[100px] md:h-[100px] md:w-[800px] '> <span className="loading loading-ring loading-lg"></span> <a href='/CompanyRequest'>تمويل سيارات للشركات </a>  <span className="loading loading-ring loading-lg"></span></button>
</div>

<div className=' text-black text-3xl text-center py-3 h-[150px]'>
  <button className='btn text-white md:text-3xl text-2xl py-5 bg-[rgb(47,114,140)]  h-[100px] md:h-[100px] md:w-[800px] '> <span className="loading loading-ring loading-lg"></span> <a href='/Offers'>الذهاب للعروض</a>  <span className="loading loading-ring loading-lg"></span></button>
</div>
{/* --------------------------------كيفية  طلب تمويل ------------------------- */}
<div  class="bg-white py-24 sm:py-32 bg-[url(https://tasweqy.sa/new/assets/img/bg/process-bg-1.jpg)]">
  <div class="mx-auto max-w-7xl  lg:px-8">
  <div data-aos="fade-up" className='text-center '>
        <img src={list} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className='w-[30px] h-[30px] m-auto'/>
        <h3 className='pb-[40px] pt-[20px] text-xl font-bold text[rgb(47,114,140)]'>كيفية طلب التمويل ؟ </h3> 
        <h1 className='text-black text-3xl font-bold pb-[70px] ' >خطوات طلب التمويل</h1>
      </div>
    <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-4 px-4">
      {/* Card-1 */}
    <div   className='py-5'>
      <div className='min-h-[320px]    hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card   ">
      <div data-aos="fade-up" className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] p-5 text-3xl   border-8 border-slate-300 ">1</div>
      <figure><img src={firstImage} alt="/" className=' p-20 md:p-10'/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >ملئ طلب التمويل</p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
            {/* Card-2 */}

      <div  className='py-5'>
      <div className=' min-h-[320px]  hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card    ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl  border-8 border-slate-300 ">2</div>
      <figure><img src={SecondImage} alt="/" className=' p-10 md:p-10'/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >طلب العروض</p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
            {/* Card-3 */}

      <div  className='py-5'>
      <div className='min-h-[320px]   hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card    ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl  border-8 border-slate-300 ">3</div>
      <figure><img src={thirdImage} alt="/" className=' p-10 md:p-10'/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >اختيار العرض المناسب </p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
            {/* Card-4 */}
      <div  className='py-5'>
      <div className='min-h-[320px]   hover:bg-amber-600 bg-white border shadow-md rounded-3xl ' >
      <div className="card    ">
      <div className="badge bg-[rgb(47,114,140)] text-white badge-lg m-2 h-[50px] w-[50px] text-3xl  border-8 border-slate-300 ">4</div>
      <figure><img src={fourthImage} alt="/" className=' p-10 md:p-10'/></figure>
  <div className="card-body rounded-b-2xl   ">
    <p className=' text-[rgb(47,114,140)] text-center text-3xl font-extrabold' >الموافقة على الطلب</p>
    <div className="card-actions justify-end">
    </div>
  </div>
</div>
      </div>
      </div>
    </dl>
  </div>
</div>
{/* --------------------------------خطوات طلب تمويل ------------------------- */}

    <div className=' py-5 px-5 text-black bg-white justify-center'>
      <div className='text-center'>
        <img data-aos="fade-up" src={listo} alt='/ رؤية لتمويل السيارات للشركات والافراد ' className='w-[90px] h-[90px] m-auto'/>
        <h3 data-aos="fade-up" className='pb-[40px] pt-[20px] text-3xl  font-extrabold text-teal-700'>شركاء النجاح</h3> 
      </div>
      <div  data-aos="fade-up">
    <img src={SuccCopration} border="0" alt="Companies" className='pb-8 mx-auto md:w-[1200px] md:h-[800px]' />
      </div>
      
      {/* --------ALL CO-OPERATIONS COMPANIES-----------  */}
      <div  data-aos="fade-up">
    <img src={Markat} border="0" alt="Companies" className='mx-auto md:w-[1200px] md:h-[800px]' />
      </div>
      </div>



      {/* ---------------STATS--------------- */}
        <div  className="px-4 py-6 grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-y-2 ">
    {/* First CARD */}
    <div data-aos="fade-up" data-aos-duration="1500" className=" flex-col  card bg-[#2f728c]  w-70 h-52 rounded-2xl  justify-center "  >
    <img className=' self-center w-[150px] h-[120px] py-3 ' src={img3} alt='/ رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي'/>
    <div className='text-center text-3xl text-white'>
    <p>+300</p>
    <p>معرض سيارات </p>
    </div>
    </div>
    {/* SECOND CARD */}
    <div data-aos="fade-up" data-aos-duration="1500" className=" flex-col  card bg-[#bca058]  w-70 h-52 rounded-2xl pb-6 justify-center "  >
    <img className=' self-center w-[150px] h-[120px] py-3' src={img2} alt='/ رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي'/>
    <div className='text-center text-3xl text-white'>
    <p>+35k</p>
    <p> طلبات تمويل </p>
    </div>
    </div>
    {/* THIRD CARD */}
    <div data-aos="fade-up" data-aos-duration="1500" className=" flex-col card bg-[#2f728c]  w-70 h-52 rounded-2xl pb-6 justify-center "  >
    <img className=' self-center w-[150px] h-[120px] py-3' src={img1} alt='/ رؤية لتمويل السيارات للشركات والافراد وللتمويل الشخصي'/>
    <div className='text-center text-3xl text-white'>
    <p>+20</p>
    <p> جهة تمويلية  </p>
    </div>
    </div>
    </div>
    </>
  )
}

export default Home