import React from 'react'
import FacebookIcon from '../assets/facebook.png'
import InstgramIcon from '../assets/instagram.png'
function Footer() {

  return (
    <div className='bg-white p-7 border shadow-2xl text-right' >
    <div data-aos="fade-up" className='   text-black '>
      <strong className='text-2xl font-bold py-2 '> تواصل معنا </strong>
      <h1 className='text-3xl py-3'> +966 55 713 4101</h1>
      <div className=' flex flex-auto text-right justify-end'>
      <a href='https://www.facebook.com/royafunding'  target="_blank" className='text-xl py-3 flex text-right '> <img className='flex flex-row h-7 w-7  ml-3' src={FacebookIcon} alt='فيسبوك'/></a>
      <a href='https://www.instagram.com/royafunding' className='text-xl py-3 flex flex-row pr-3 pl-1'> <img className='flex flex-row h-7 w-7 ml-3' src={InstgramIcon} alt='انستجرام'/></a>
      <strong className='text-2xl font-bold py-2 '>: تابعنا  </strong>
      </div>
      <h1 className=' text-slate-700 py-3 text-xl ' >المملكة العربية السعودية <span> , الرياض </span></h1>
    </div>
  <hr/>
<div data-aos="fade-up" className='md:grid md:grid-cols-2'>
<div className=' font-mono '>
      <h1 className='text-3xl text-black text-right font-bold md:pt-9'>قدم طلبك الان </h1>
      <div className='text-right font-bold'>
        <div className='text-slate-600'>
        <p className='py-2'><a href='/IndividualRequest' className='py-2 cursor-pointer'>  تمويل سيارات افراد </a></p>
        <p className='py-2'><a href='/CompanyRequest' className='py-2 cursor-pointer'> تمويل سيارات  شركات</a></p>
        {/* <p className='py-2'><a href='/PersonalRequest'  className='py-2 cursor-pointer'>تمويل شخصي  </a></p> */}
        </div>

      </div>
</div>
    <div data-aos="fade-up" className=' font-mono py-7 '>
<h1 className='text-3xl text-black text-right font-bold '> روابط مفيدة </h1>
<div className='text-right font-bold'>

        <div className='text-slate-600   '>
          <div className='' >
        <p className='py-2'><a href='/ourServices' className='py-2'> خدماتنا </a></p>
          </div>
        <p className='py-2'><a href="/PrivacyPolicy"> سياسة الخصوصية</a> </p>
        {/* <p className='py-2'><a href="/Whyus"> لماذا نحن ؟ </a>  </p> */}
        </div>
        <div className='text-slate-600'>
        <p className='py-2'><a href='/offers' >العروض </a></p>
        <p><a href='/about' className='py-2'>عن الشركة </a></p>
        <p className='py-2'><a href='/contact'>اتصل بنا </a>  </p>
        </div>
      </div>
    </div>
    </div>
    <div className=''>
    Copyright © {Date().split("22")[0]} Roya-Funding. All Rights Reserved.
    </div>
    </div>
  )
}

export default Footer